<template>
  <div>
    <v-alert type="error" :value="!!error" border="left">
      {{ error }}
    </v-alert>

    <div 
      v-if="status.length" 
      v-html="status.join('<br>')"
      class="caption"
    ></div>
  </div>
</template>

<script>
import JSZip from 'jszip'
import firebase from 'firebase'
import { saveAs } from 'file-saver'
import { mapState, mapActions } from 'vuex'

// MIXINS
import downloadRecipe from '../../mixins/download-recipe-pdf'

var zip = new JSZip()

export default {
  metaInfo: {
    title: 'Download Recipe PDF'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      ids: [],
      status: [],
      zip: false,
      error: null,
      recipe: null,
      downloaded: 0,
      requesting: false,
      startDownload: false,
      image: '/placeholder.svg',
    }
  },

  /*------------------------------------------------------------------------------
   * MIXINS
   *----------------------------------------------------------------------------*/
  mixins: [downloadRecipe],

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      recipes: state => state.recipes.recipes,
      myRecipes: state => state.recipes.myRecipes,
      currentMealServes: state => state.recipes.currentMealServes
    }),
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': {
      handler(user) {
        if (user && !this.startDownload) {
          this.startDownload = true
          var recipeId = this.$route.query.id
          var recipeIds = this.$route.query.ids

          if (recipeId) this.getRecipeDetails(recipeId)
          else if (recipeIds) {
            this.zip = true
            let decoded = decodeURIComponent(recipeIds)
            this.ids = decoded.split("|")
            this.getRecipeDetails(this.ids[0])
          }
          else this.error = 'Recipe ID not provided.'
        }
      },
      immediate: true
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('recipes', [
      'getRecipe',
      'getServesByMeal'
    ]),

    /*------------------------------------------------------------------------------
     * GET RECIPE DETAILS
     *----------------------------------------------------------------------------*/
    getRecipeDetails(recipeId) {
      this.status.push('Getting recipe details...')

      // Get Meal Serves
      const planId = this.$route.query.plan
      const mealId = this.$route.query.meal

      var promises = [this.getRecipe({id: recipeId}), this.getServesByMeal({ planId, mealId })]

      if (!this.$store.state.tags.status.firstLoad) promises.push(this.$store.dispatch('tags/getTags'))
      if (!this.$store.state.nutritiontags.status.firstLoad) promises.push(this.$store.dispatch('nutritiontags/getTags'))
      
      Promise.all(promises)
      .then((errors) => {
        if (errors[0]) this.error = errors[0]
        else {
          this.recipe = this.recipes.find(r => r.id == recipeId)
          if(!this.recipe)
            this.recipe = this.myRecipes.find(r => r.id == recipeId)

          console.log(this.recipe)

          this.status.push(`Creating "${this.recipe.name}" PDF...`)

          this.serves = this.currentMealServes

          Promise.resolve(this.getPhotoUrl(this.recipe))
          .then((url) => {
            if (url) this.image = url
            
            Promise.resolve(this.downloadPDF(this.zip))
            .then((doc) => {
              zip.file(`${this.recipe.name}.pdf`, doc.output('blob'))

              if (this.ids.length) {
                let id = this.ids.find(i => i == this.recipe.id)
                let index = this.ids.indexOf(id)
                if (index + 1 < this.ids.length) {
                  this.getRecipeDetails(this.ids[index + 1])
                }
                else {
                  zip.generateAsync({type:'blob'})
                  .then((content) => {
                    saveAs(content, 'recipes.zip')
                  })
                }
              }
            })
          })
        }
      })
    },

    /*------------------------------------------------------------------------------
     * GET PHOTO URL
     *----------------------------------------------------------------------------*/
    getPhotoUrl(recipe) {
      return new Promise((resolve, reject) => {
        // if (recipe.mediumPhotoUrl) {
        //   let img = new Image()
        //   img.src = recipe.mediumPhotoUrl
        //   resolve(recipe.mediumPhotoUrl)
        // }
        if (recipe.OriginalPhotoUrl) {
          let img = new Image()
          img.src = recipe.OriginalPhotoUrl
          resolve(recipe.OriginalPhotoUrl)
        }
        else {
          firebase.storage()
          .ref(`recipes/medium_${recipe.photo}`)
          .getDownloadURL()
          .then(url => {
            let img = new Image()
            img.src = url
            resolve(url)
          })
          .catch((error) => {
            console.log(error.message)
             switch (error.code) {
              case 'storage/object-not-found':
                resolve("")
                break;
              default:
                reject(error.message)
            }
          })
        }
      })
    },
    addFixedDecimals(val) {
      if(val % 1 != 0) 
        return this.$options.filters.amount(val)

      return val
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  async mounted() {
    var brand = this.$route.query.b

    if (brand && !this.user) {
      Promise.resolve(this.$store.dispatch('members/getMember', brand))
      .then(doc => {
        let user = doc.data()
        user.id = doc.id
        user.ref = doc.ref
        this.$store.commit('user/setUser', user)
      })
    }
  }
}
</script>